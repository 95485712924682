<template>
  <ul class="filter-chambers">
    <li>
      <input class='input' type="text" v-model="fio" :placeholder="$store.getters.GET_LANG ? 'Поиск' : 'Search'"
             @keyup.enter="apiMemberList()">
      <button type="button" @click="apiMemberList()" class="btn__icon btnSearch"></button>
    </li>
    <li :class="{'display_filter': showFilter===true}">
      <custom-select :key="`inclusion${count}`" class="select" :list='getInclusionList' @InputsContent="setInclusion"
                     :placeholder="$route.query.plan ? $route.query.plan : castomLang" :castom="castomLang"/>
      <custom-select :key="`unit${count}`" class="select" :list='getUnitTypeList' @InputsContent="setUnit"
                     :placeholder="$store.getters.GET_LANG ? 'Выберите рабочий орган' : 'Select a working body'"
                     :castom="castomLang"/>
      <button type="button" @click="resetFilters()">{{ $store.getters.GET_LANG ? 'Сбросить фильтры' : 'Reset the filters' }}
      </button>
    </li>
    <li>
      <p @click="showFilter = !showFilter" :class="{'transform-button': showFilter}">
        <span v-if="!showFilter">{{ $store.getters.GET_LANG ? 'Показать' : 'Show' }}</span>
        <span v-else>{{ $store.getters.GET_LANG ? 'Свернуть' : 'Collapse' }}</span> {{ $store.getters.GET_LANG ?
        'фильтры' : 'filters' }}</p>
      <button type="button" @click="$store.dispatch('setView', false)" class="btn__icon btnBlock"
              :class="{'active' : getListView}"></button>
      <button type="button" @click="$store.dispatch('setView', true)" class="btn__icon btnList"
              :class="{'active' : !getListView}"></button>
    </li>
  </ul>
</template>

<script>
import CustomSelect from '@/components/forms/CustomSelect'
import currentYear from '@/mixins/currentYear'

export default {
  name: 'FilterMembersChamber',
  mixins: [currentYear],
  props: {
    year: {
      type: Number
    }
  },
  data () {
    return {
      fio: '',
      count: 0,
      showFilter: false,
      getUnitTypeList: []
    }
  },

  mounted () {
    // this.$store.commit('setInclusion', value)
    this.getData()
    // this.$store.dispatch('apiInclusionList')
    // this.$store.dispatch('apiUnitTypeList')
  },

  methods: {
    getData () {
      this.$store.dispatch('apiInclusionList', {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      this.$store.dispatch('apiUnitTypeList', {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
    },
    resetFilters () {
      this.fio = ''
      this.$store.commit('setLetter', '')
      this.$store.commit('setInclusion', '')
      this.$store.commit('setUnit', '')
      this.count += 1
      this.$store.dispatch('apiMemberList', {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        },
        year: this.getCurrentYear()
      })
    },
    setInclusion (value) {
      this.$store.commit('setInclusion', value)
      this.apiMemberList()
    },
    setUnit (value) {
      this.$store.commit('setUnit', value)
      this.apiMemberList()
    },
    apiMemberList () {
      const params = {
        year: this.year || 2018
      }
      if (this.getInclusion) this.$set(params, 'inclusionGround', this.getInclusion.code)
      if (this.getUnit) this.$set(params, 'unit_type', this.getUnit.code)
      if (this.$store.getters.getSearchFio) this.$set(params, 'name', this.$store.getters.getSearchFio)

      this.$store.dispatch('apiMemberList', params)
      // {
      //   headers: {
      //     Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
      //   },
      //   year: 2018
      // })
    }
  },

  watch: {
    fio (value) {
      this.$store.commit('setSearchFio', value)
    },
    '$store.getters.GET_LANG' () {
      this.getData()
    },
    '$route' () {
      if (this.$route.query.plan) {
      } else this.resetFilters()
    },
    getInclusionList () {
      if (this.$route.query.plan) {
        const dataArr = this.$store.getters.getInclusionList
        for (var item of dataArr) {
          if (item.title === this.$route.query.plan) {
            this.setInclusion(item)
            break
          }
        }
      }
    },
    '$store.getters.getUnitTypeList' () {
      const dataList = this.$store.getters.getUnitTypeList
      var arr = []
      if (this.$store.getters.GET_LANG) {
        dataList.forEach(item => {
          if (item.title !== 'Без типа') { arr.push(item) }
        })
      } else {
        dataList.forEach(item => {
          if (item.title !== 'Без типа') {
            arr.push({ ...item, title: item.title_eng })
          }
        })
      }
      this.getUnitTypeList = arr
    }
  },

  computed: {
    castomLang () {
      return this.$store.getters.GET_LANG ? 'Все' : 'All'
    },
    getListView () {
      return this.$store.getters.getListView
    },
    getInclusionList () {
      return this.$store.getters.GET_LANG ? this.$store.getters.getInclusionList
        : this.$store.getters.getInclusionList.map(item => {
          return {
            ...item,
            title: item.titleEng
          }
        })
    },
    // typeList () {
    //   return this.$store.getters.getUnitTypeList
    // },
    getInclusion () {
      return this.$store.getters.getInclusion
    },
    getUnit () {
      return this.$store.getters.getUnit
    }

  },

  components: {
    'custom-select': CustomSelect
  }
}
</script>

<style scoped lang="scss">
  .filter-chambers {
    display: flex;
    margin: 2rem 0;

    li {
      display: flex;
      position: relative;
      margin-right: 0.625rem;

      input {
        background: #EEF4FA;
        border-radius: 3px;
      }
    }

    li:nth-child(1) {
      width: 26.5625rem;

      button {
        border: none;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
    }

    li:nth-child(2) {
      flex: 1;

      .select {
        width: 16.875rem;
        margin-right: 0.625rem;
      }

      button {
        font-size: 0.75rem;
        margin-left: auto;
        padding: 0 1rem;

        border: none;
        background: inherit;
        color: #003E78;
        opacity: 0.6;

        cursor: pointer;
      }
    }

    li:nth-child(3) {
      p {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .filter-chambers {
      flex-wrap: wrap;
      justify-content: space-between;

      li:nth-child(1) {
        margin: 0 30% 1.375rem 0;
      }

      li:nth-child(2) {
        order: 3;

        button {
          position: absolute;
          left: 83%;
          top: -3.25rem;
        }
      }

      li:nth-child(3) {
        margin-bottom: 1.375rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .filter-chambers {
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        margin-right: 0;
        align-items: center;
      }

      li:nth-child(1) {
        margin-right: 0;
        width: 21.35rem;

        input {
          padding: 0.4rem 1.25rem;
        }
      }

      li:nth-child(2) {
        display: none;

        .select {
          width: 13.89rem;
        }

        button {
          position: relative;
          left: 0;
          top: 0;
          padding-right: 0;
        }
      }

      .display_filter {
        display: flex !important;
      }

      li:nth-child(3) {
        p {
          display: block;
          font-size: .75rem;
          line-height: 1rem;
          color: #246CB7;
          margin-right: .935rem;
          padding-left: 1.43rem;
          cursor: pointer;
        }

        p:before {
          content: '';
          width: 1.25rem;
          height: 1rem;
          background: url('../../assets/icons/arrow.svg') center center no-repeat;
          padding: 0.5rem;
          margin-right: .19rem;
          position: absolute;
          left: 0;
        }

        .transform-button:before {
          transform: rotate(180deg);
          padding: 0.6rem;
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    .filter-chambers {
      li:nth-child(1) {
        width: 100%;
      }

      li:nth-child(2) {
        width: 100%;
        flex-direction: column;

        .select {
          width: 100%;
          margin-right: 0;
          margin-bottom: .94rem;
        }

        button {
          margin: 0;
          opacity: 1;
          padding: 0;
          color: #246CB7;
        }
      }

      li:nth-child(3) {
        width: 100%;
        justify-content: flex-end;

        p {
          position: absolute;
          left: 0;
        }
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    button {
      background: none;
    }
  }

</style>
