<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>
    <div class="white-container members-chamber">
      <section class="members-chamber__title">
        <h3>{{ $store.getters.GET_LANG ? 'Члены Общественной палаты' : 'Members of chamber' }}</h3>
        <filter-convocation-council @update-year="(e) => year = e"  class="block-active_item-list"/>
      </section>
      <tricolor-line />
      <filter-members-chamber :year="year"/>
      <abc-block />
      <component :is="`templated-${getListView ? 'list' : 'block'}`" :data="getMemberList" />
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import ABC from '@/components/membersCamber/ABC'
import FilterMembersChamber from '@/components/membersCamber/FilterMembersChamber'
import TemplatedList from '@/components/membersCamber/TemplatedList'
import TemplatedBlock from '@/components/membersCamber/TemplatedBlock'
import FilterConvocationCouncil from '@/components/memberProfile/FilterConvocationCouncil'
import currentYear from '@/mixins/currentYear'

export default {
  name: 'MembersPublicChamber',
  mixins: [currentYear],
  data () {
    return {
      contentLink: [],
      year: this.currentYear
    }
  },
  created () {
    this.$store.dispatch('setView', false)
    // this.getData()
    // this.$store.dispatch('apiMemberList', { year: this.getCurrentYear() }) // from mixin
  },

  computed: {
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'О палате' : 'About',
          path: '/members_chamber'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Члены Общественной палаты' : 'Members of chamber'
        }
      ]
    },
    getMemberList () {
      return this.$store.getters.getMemberList
    },
    getListView () {
      return this.$store.getters.getListView
    },
    getSearchFio () {
      return this.$store.getters.getSearchFio
    }
  },
  watch: {
    '$store.getters.GET_LANG' () {
      this.getData()
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('apiMemberList', {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        },
        year: this.year
      })
    }
  },
  mounted () {
    document.title = 'Члены Общественной палаты'
  },
  components: {
    'bread-crumb': BreadCrumb,
    'tricolor-line': TricolorLine,
    'abc-block': ABC,
    'filter-members-chamber': FilterMembersChamber,
    'templated-list': TemplatedList,
    'templated-block': TemplatedBlock,
    FilterConvocationCouncil
  }
}
</script>

<style scoped lang="scss">
.members-chamber {

  h3{
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 127%;
    opacity: 1;
    color: #1F3245;
  }
  &__title {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
    * {
      font-size: 0.75rem;
      line-height: 150%;
      padding: .5rem 0.75rem;

      color: #003E78;
    }
    .block-active_item-list{
      display: flex;
      padding: 0;
      margin-left: auto;
    }
    .select_block {
      margin-bottom: 0;
    }
    .active_item {
      background: #EEF4FA;
      border-radius: 3px;
      padding-right: 7.4rem;
      margin-left: auto;
    }
    .lists {
      padding-left: 1.375em;
      display: flex;
      align-items: center;
    }
    .lists::after {
      content: '';
      margin-left: 0.75rem;
      width: 0.7rem;
      height: 0.7rem;
      background-image: url('../../assets/icons/icon_link.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

}
@media screen and (max-width: 768px){
  .members-chamber {
    &__title {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2rem;
      h3{
        padding-left: 0;
      }
    }
    .block-active_item-list{
      padding-top: 1.37rem;
      margin-left: 0;
    }
  }

}
@media screen and (max-width: 420px){
  .members-chamber {
    h3{
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 127%;
      opacity: 1;
      color: #1F3245;
      margin-bottom: 1rem;
      padding: 0;
    }
    &__title {
      margin-bottom: 1.1rem;
      .active_item {
        width: 100%;
        margin-bottom: .5rem;
      }
      .lists {
        padding-left: 0;
        display: flex;
        align-items: center;
      }
    }
    .block-active_item-list{
      flex-direction: column;
    }
  }
  .template_list{
        max-height: unset;
    }
}
</style>
