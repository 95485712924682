<template>
  <ul class="abc">
    <li v-for="(item, key) in arr_ru"
      :class="{'active' : item ? item.toUpperCase() === getLetter : item === getLetter}"
      :key="`abc${key}`"
      @click="setFilter(key)">
      {{ item ? item : $store.getters.GET_LANG ? 'все' : 'all' }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ABC',
  data () {
    return {
      arr_ru: [] // ['', 'а', 'б', 'в', 'г', 'д', 'е', 'ж', 'з', 'и', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'э', 'ю', 'я']
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    setFilter (key) {
      this.$store.commit('setLetter', this.arr_ru[key] !== undefined ? this.arr_ru[key].toUpperCase() : null)
    },
    getData () {
      this.arr_ru = this.arr
    }
  },
  computed: {
    arr () {
      return this.$store.getters.GET_LANG
        ? ['', 'а', 'б', 'в', 'г', 'д', 'е', 'ж', 'з', 'и', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'э', 'ю', 'я']
        : ['', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
    },
    getLetter () {
      return this.$store.getters.getLetter
    }
  },
  watch: {
    '$store.getters.GET_LANG' () {
      this.getData()
    }
  }
}
</script>

<style scoped lang="scss">
.abc {
  display: flex;
  margin-bottom: 2rem;

  li {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    padding: .3125rem .4375rem;
    margin-right: .4375rem;

    color: #246CB7;
    background-color: #EEF4FA;
    border-radius: 3px;

    cursor: pointer;
  }
  li.active {
    background: #246CB7;
    color: #FFFFFF;
  }
}
@media screen and (max-width: 768px){
  .abc {
    overflow-x: auto;
  }
}
</style>
