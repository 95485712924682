<template>
  <div v-if="getLetter">
    <p class="template_block__title">{{ getLetter }}<span></span></p>
    <div class="template_block__members">
      <card-member-board v-for="(member, key) in data[`${getLetter}`]" :templated="member" :key="`member${key}`" />
    </div>
  </div>
  <div v-else>
    <div v-for="(item, key, index) in data" :key="`item${index}`" >
      <p v-if="data[`${key}`].length" class="template_block__title">{{ key }}<span></span></p>
      <div class="template_block__members">
        <card-member-board v-for="(member, key) in item" :templated="member" :key="`member${key}`" />
      </div>
    </div>
  </div>
</template>

<script>
import CardMemberBoard from '@/components/indexPage/CardMemberBoard'

export default {
  name: 'TemplatedBlock',
  props: {
    data: {
      type: Object
    }
  },

  methods: {
    //
  },
  computed: {
    getLetter () {
      return this.$store.getters.getLetter
    }
  },
  components: {
    'card-member-board': CardMemberBoard
  }
}
</script>

<style scoped lang="scss">
.template_block {

  &__title {
    font-weight: 600;
    font-size: 1.625rem;
    line-height: 115%;
    color: #306392;

    display: flex;
    align-items: center;
    margin: 1.4375rem 0;

    span {
      flex: 1;
      background-color: #D5D6D9;
      height: 0.13rem;
      border-radius: 0.13rem;

      margin-left: 1.625rem;
    }
  }
  &__members {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.125rem;

    .card_wrapper {
      width: calc(16.666% - 2.25rem);
      margin: 1.0625rem 1.125rem;
    }
  }
}
@media screen and (max-width: 768px){
  .template_block {
    &__members {
      margin: 0 -0.5rem;
      .card_wrapper {
        width: calc(25% - 1rem);
        height: 12.6rem;
        margin: .625rem .5rem;
        padding: 0.93rem .93rem;
      }
    }
  }
}
@media screen and (max-width: 420px){
  .template_block {

    &__title {
      font-weight: 600;
      font-size: 1.625rem;
      line-height: 115%;
      color: #306392;

      display: flex;
      align-items: center;
      margin: 1.4375rem 0;

      span {
        flex: 1;
        background-color: #D5D6D9;
        height: 0.13rem;
        border-radius: 0.13rem;

        margin-left: 1.625rem;
      }
    }
    &__members {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0;

      .card_wrapper {
        width: 48%;
        margin: .0625rem .125rem;
        min-height: 8rem;
        margin-bottom: .7rem;
      }
    }
  }

}
</style>
