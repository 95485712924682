<template>
  <div v-if="getLetter" class="template_list">
    <ul>
      <li>{{ getLetter }}</li>
      <li v-for="(member, key) in data[`${getLetter}`]" :key="`member${key}`" >
        <router-link :to="`/member_profile/${member.slug}`">{{ member.fullName }}</router-link>
      </li>
    </ul>
  </div>
  <div v-else class="template_list">
    <ul v-for="(item, key, index) in data" :key="`item${index}`" >
      <li v-if="data[`${key}`].length">{{ key }}</li>
      <li v-for="(member, key) in item" :key="`member${key}`">
        <router-link :to="`/member_profile/${member.slug}`">{{ member.fullName }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TemplatedBlock',
  props: {
    data: {
      type: Object
    }
  },
  methods: {
    //
  },
  computed: {
    getLetter () {
      return this.$store.getters.getLetter
    }
  }
}
</script>

<style scoped lang="scss">
.template_list {
  column-count: 3;
  -webkit-column-count: 3;
  -moz-column-count: 3;

  ul {
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
    li {
      font-size: 0.875rem;
      line-height: 150%;
      color: #246CB7;
      margin: 0.75rem 0  0.75rem 1.1rem;
      list-style-type: disc;

      cursor: pointer;
    }
  }

  ul li:first-child {
    font-weight: 600;
    font-size: 1.625rem;
    line-height: 115%;
    color: #306392;

    display: flex;
    align-items: center;
    margin: 0 0 1.25rem 0;
  }

  ul li:last-child {
    margin-bottom: 1.375rem;
  }
  a {
    color: #246CB7;
  }
}
@media screen and (max-width: 768px){
  .template_list {
    column-count: 2;
    -webkit-column-count: 2;
    -moz-column-count: 2;
  }
}
@media screen and (max-width: 420px){
  .template_list {
    column-count: 1;
    -webkit-column-count: 1;
    -moz-column-count: 1;
  }
}
</style>
